<template>
  <div>
    <div v-if="loading" class="h-64 flex items-center justify-center">
      <fa :icon="['fal', 'spinner-third']" size="10x" spin></fa>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: { loading: { type: Boolean, required: true } }
};
</script>

<style scoped></style>
