<template>
  <div v-if="!error">
    <slot></slot>
  </div>
  <Alert v-else class="m-16" type="error" show-icon>
    {{ $t("OUPS_SOMETHING_WENT_WRONG") }}
    <template slot="desc">
      <p>{{ $t("TRY_AGAIN_IN_A_FEW_MINUTES_OR_CONTACT_OUR_SUPPORT") }}</p>
      <Collapse
        v-if="typeof error === 'string'"
        simple
        class="border-none bg-transparent mb-2"
      >
        <Panel>
          {{ $t("SEE_TECHNICAL_DETAILS") }}
          <template slot="content">{{ error }}</template>
        </Panel>
      </Collapse>
      <a href="mailto:support@delight-data.com">
        <Button type="primary">
          <fa icon="envelope"></fa>&nbsp; support@delight-data.com
        </Button>
      </a>
    </template>
  </Alert>
</template>

<script>
export default {
  props: { error: { type: [Boolean, String], default: false } }
};
</script>

<style scoped></style>
