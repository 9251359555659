<template>
  <Loader :loading="$apollo.loading">
    <Error :error="error">
      <Tabs :value="$route.name" @on-click="goToTab">
        <TabPane
          v-for="{ name, meta: { labelKey } } in tabs"
          :key="name"
          :label="$t(labelKey)"
          :name="name"
        />
      </Tabs>
      <RouterView />
    </Error>
  </Loader>
</template>

<script>
import routes from "./routes";
import Loader from "@/components/Loader/Loader.vue";
import Error from "@/components/Error/Error.vue";
import tenantQuery from "./queries/tenant.gql";

export default {
  components: { Loader, Error },
  props: { organizationId: { type: String, required: true } },
  data: () => ({ organization: null, error: null }),
  provide() {
    return this.$data;
  },
  apollo: {
    organization: {
      query: tenantQuery,
      variables() {
        return { id: this.organizationId };
      },
      update: ({ tenant }) => tenant,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(graphQLErrors || networkError);
      }
    }
  },
  computed: { tabs: () => routes },
  methods: {
    goToTab(name) {
      // the .catch is to prevent a useless "NavigationDuplicated" error in
      // console when the user try to go to the current location
      this.$router.push({ name }).catch(() => {});
    }
  }
};
</script>

<style scoped></style>
